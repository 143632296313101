/* #region ---- GLOBAL STYLING  ----*/

body{
    font-family:Montserrat;
}
body button:hover{
    cursor: pointer;
}
@font-face {
    font-family: Montserrat;
    src: url("../data/Montserrat-VariableFont_wght.ttf");
  }
.heading{
    font-weight: bold; 
    font-size: 40px;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 100px;
}

@media screen and (min-width: 480px){
    .text{
        text-align: left;
        font-size: 20px;
        margin: 50px;
        line-height: 1.8;
        transform: translatey(-35px)
    }

    .title{
        text-align: center;
        font-size: 2.5vw;
        font-weight: bold;
        margin-top: 50px;
        margin-bottom: 25px;
    }
}

@media screen and (max-width: 1024px){
    .text{
        text-align: left;
        font-size: 4vw;
        margin: 10px;
        line-height: 1.3;
        transform: translatey(-35px)
    }

    .title{
        text-align: center;
        font-size: 5vw;
        font-weight: bold;
        margin-top: 50px;
        margin-bottom: 25px;
    }
}


a, a:visited, a:hover, a:active {
    color: black;
    text-decoration: none;
  }
button{
    border: none;
    outline: none;
}

/* Reset Chakra UI Input styles */
input {
    border: none;
    outline: none;
  }

/* #endregion */

/* #region ---- NAVIGATION BAR  ---- */

@media screen and (max-width: 1024px){
    .menu-btn{
        background-color: white;
        font-size: 5vw;
        visibility: visible;
        opacity: 1;
        padding-left: 10vw;
    }

    .NavLink, .LinkedIn, .Instagram{
        visibility: hidden;
    }

    .NavLink img{
        width: 30vw;
        height: auto;
        visibility: visible;
    }
}

@media screen and (min-width: 480px){
    .Navbar{
        justify-content: space-around;
        position: sticky;
        top: 0;
        z-index: 1500;
        background-color: white;
        display: flex;
    }

    .NavLink:hover{
        text-decoration: underline;
        color:#016320;
    }

    .active{
        border-bottom: 3px solid #016320;
    }

    .NavLink:link{
        color: black;
        text-decoration: none;
        font-size: 25px;
    }

    .NavLink img{
        width: 150px;
        height: 25%;
    }
    .NavLink:visited {
        color: black;
    }
    
    .LinkedIn{
        font-size: 24px;
        color: black;
    }
    
    .Instagram{
        font-size: 24px;
        color: black
    }

    .menu-btn{
        visibility: hidden;
        opacity: 0;      
    }
}


/* #endregion */

/* #region  ---- HOME PAGE ---- */
.intro{
    text-align: center;
    font-size: 80px;
    margin-top: 135px;
    margin-bottom: 85px;
    font-weight: bold;
}
.description{
    text-align: center;
    font-size: 30px;
}
.calltoaction{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 100px;
}
.calltoaction button{
    color:black;
    font-size: 20px;
    font-weight: bold;
    font-family: Montserrat;
    border-radius: 50px;
    border: 5px solid #00621C; 
    background-color: white;
    width: 250px;
    height: 50px;
    margin-top: 100px;
}
.calltoaction button:hover{
background-color: #86b58f;
}
.weare{
    text-align: left;
}
.mission-statement-title{
    font-size: 80px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 100px;
}
.mission-statement-text{
    text-align: center;
    font-size: 30px;
    margin: 40px 40px;
    font-weight: 600;
}
.Moreinfo{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 70px;
}
.Moreinfo img{
    width: 32%;
    height: 32%;
    margin: 20px 20px;
}

@media screen and (min-width: 480px){
    .intro{
        text-align: center;
        font-size: 4vw;
        margin-top: 15vw;;
        margin-bottom: 1.5vw;
        font-weight: bold;
    }
    .description{
        text-align: center;
        font-size: 25px;
    }

    .calltoaction{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-bottom: 12vw;
    }
    .calltoaction button{
        color:black;
        font-size: 20px;
        font-weight: bold;
        font-family: Montserrat;
        border-radius: 50px;
        border: 5px solid #00621C; 
        background-color: white;
        width: 15vw;
        height: 4vw;
        margin-top: 4vw;
    }

    .join-button{
        left: 70%;
        position: relative;
    
    }

    .consultation-button{
        right: 70%;
        position: relative;
    }

    .Moreinfo{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-top: 4vw;
    }
    
    .Moreinfo img{
        width: 32%;
        height: 32%;
        margin: 20px 20px;
    }

    .mission-statement-title{
        font-size: 4vw;
        text-align: center;
        font-weight: bold;
        margin-bottom: 1vw;
        color: #00621C;
    }
    
    .mission-statement-text{
        text-align: center;
        font-size: 1.5vw;
        margin: auto;
        font-weight: 600;
        max-width: 1200px;
        width: auto;
        color: #016320;
    }

    .cards-container{
        position: relative;
        bottom: 100px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
    
    .card{
        width: 90%;
        max-width: 320px;
        height: 390px;
        box-shadow: 0 4px 10px -2px gray;
        border-radius: 10px;
        overflow: hidden;    
        padding: 10px;
        margin-bottom: 50px;
        font-family: Montserrat;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
    }
    
    .card:hover{
       border: 5px solid #016320;
    }
    .animation{
        top: 19vw;
        margin-left: 2%;
        position: relative;
        font-size: 1.5vw;
        color: white;
    }
    .animation-Body{
        font-size: 2.5vw;
        color: white;
    }
    
}

@media screen and (max-width: 1024px) {
    .intro{
        text-align: center;
        font-size: 10vw;
        margin-top: 50vw;
        margin-bottom: 5vw;
        font-weight: bold;
    }
    .description{
        text-align: center;
        font-size: 4vw;
    }

    .calltoaction{
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .calltoaction button{
        color:black;
        font-size: 10px;
        font-weight: bold;
        font-family: Montserrat;
        border-radius: 50px;
        border: 1vw solid #00621C; 
        background-color: white;
        width: 40vw;
        height: 9vw;
        margin-top: 5vw;
    }   
    .join-button, .consultation-button{
        left: 10%;
    }
    .Moreinfo{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 7vw;
    }

    .Moreinfo img{
        width: 30%;
        margin: 5vw 0;
    }

    .mission-statement-title{
        font-size: 8vw;
        text-align: center;
        font-weight: bold;
        margin-bottom: 5vw;
        color: #00621C;
    }

    .mission-statement-text{
        text-align: center;
        font-size: 5vw;
        margin: auto;
        font-weight: 600;
        max-width: 1200px;
        width: auto;
        color: #016320;

    }
    .cards-wrapper{
        margin-top: 100px;
    }
    .cards-container{
        align-self: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
    
    .card{
        width: 80%;
        max-width: 320px;
        height: 400px;
        box-shadow: 0 4px 10px -2px gray;
        border-radius: 10px;
        overflow: hidden;    
        padding: 10px;
        margin-bottom: 50px;
        font-family: Montserrat;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
    }
    
    .card:hover{
       border: 5px solid #016320;
    }

    .animation{
        margin-left: 8vw;
        position: relative;
        font-size: 5vw;
        color: white;
    }
    .animation-Body{
        font-size: 7vw;
        color: white;
    }
    
}

.background{
    padding-top: 55px;
    background-color: #EAEAEA;
}
/* #endregion */

/* #region ---- CONTACT PAGE ---- */
.contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-align: center;
  }
.contact button{
    color: white;
    font-size: 15px;
    border-radius: 10px;
    border: none;
    background-color: #1A2421;
    width: 250px;
    height: 50px;
    margin-top: 25px;
}
.contact-inquiry{
    margin-top: 25px;
}

.inputbox{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 25px auto;
    width: 450px; 
}
.inputbox label{
    width: 100%;
    text-align: left;
}
.input {
    font-family: Montserrat;
    width: 100%;
    height: 40px;
    font-size: 20px;
    background-color: #DEDEDE;
    border: 2px solid grey; 
    border-radius: 10px;  
    border-width: medium;
  }
  
textarea{
    font-family:Montserrat;
    background-color: #DEDEDE;
    font-size: 20px;
    width: 100%;
    height: 200px;
    border-width: medium;
    border-color: grey;
    border-radius: 10px;
}

.about{
    display: flex;
    flex-direction: column;
    margin: 100px 100px;
}
.about img{
    height: 300px;
    width: 325px;
}
.maincard{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 50px
}
.content{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.GradRadio{
    text-align: left;
}
.ContactRadio{
    text-align: left;
}

.react-tabs__tab-list {
    display: flex;
    justify-content: center;
    background: #fff;
    border-bottom: 4px solid #00621C;
    list-style: none;
    padding: 0;
    margin-top: 25px;
  }
  
  .react-tabs__tab {
    padding: 10px 20px;
    cursor: pointer;
    border: 3px solid #ddd;
    border-bottom: none;
    background: #fff;
    margin: 0;
  }
  
  .react-tabs__tab--selected {
    background: #fff;
    border-color: #00621C;
    color: #333;
  }

  @media screen and (max-width: 1024px) {
    .input {
        width: 75%;
      }
      
    textarea{
        width: 75%;
    }
}

/* #endregion */

/* #region ---- ABOUT PAGE ---- */

.studentstext{
    margin-top: -50px;
}
.clientstext{
    margin-top: -50px
}


.bottom-segment {
    display: flex;
    justify-content: space-evenly;
    background-color: #fff; 
    margin-top: 150px;
  }
.icon-link{
    text-align: center;
}
.icon {
    width: 30%;
    height: 30%;
    color: black;
}
/* #endregion */

/* #region ---- THANK YOU PAGE ---- */

/* #region ---- SERVICE PAGE ----*/
.thank-you{
    text-align: center;
    font-weight: bold;
    font-size: 36px;
    padding-top: 15%;
    padding-bottom: 10%;
}

.piechart{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.upper{
    text-align: center;
}
.piechart-buttons{
    margin-bottom: 75px;
}
.piechart-buttons button{
    color:black;
    font-size: 14px;
    font-weight: bold;
    font-family: Montserrat;
    border-radius: 50px;
    border: 5px solid #00621C; 
    background-color: white;
    width: 175px;
    height: 40px;
    margin: 100px 25px;
}
.piechart-buttons button:hover{
    background-color: #86b58f;
}
.recharts-text{
    fill: black;
}
.aboutus{
    text-align: center;
    font-weight: 700;
    font-size: 70px;
    margin-top: 75px;
}
.carousel {
    overflow: hidden;
    max-width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    
  }
  
  .inner {
    white-space: nowrap;
    transition: transform 0.3s;
  }
  
  .carousel-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 490px;
    background-color: #fff;
  }
  
  .carousel-item-text {
    font-size: 1.15rem;
    margin: 40px 0 40px 0;
    padding: 0 40px 0 40px;
    white-space: normal;
    text-align: left;
    width: 500px;
  }
  .carousel-img {
    max-width: 650px;
    padding: 0 20px 0 20px;
  }
  
  /* final stage */
  
  .carousel-buttons {
    display: flex;
    justify-content: space-evenly;
  }
  
  .button-arrow {
    background: none;
    border: none;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .indicators {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
  }
  
  .indicator-buttons {
    border: none;
    cursor: pointer;
  }
  
  .indicators button {
    margin: 5px;
    background: none;
  }
  
  .indicator-symbol {
    color: #26343f;
  }
  
  .indicator-symbol-active {
    color: #00621C;
  }

  .service-info {
    padding: 10px;
    padding-top: 100px;
    display: flex;
    margin-left: 15px;
    margin-right: 15px;
  }
  .service-info-text {
    font-size: 25px;
    width: inherit;
    margin: 10px 50px;
  }
.recharts-wrapper{
    margin-top: -100px;
    align-self: center;
}
.about-intro{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.service-info img{
    width: 500px;
    height: 400px;
}
.carousel-item-title{
    font-weight: 750;
    padding-bottom: 2vw;
}
.service-title{
    text-align: center;
    font-weight: 800;
    font-size: 40px;
    margin-bottom: -20px;
}

/* #endregion */